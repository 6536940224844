import React, { useEffect, useState } from 'react';

const TypesOfContainers = () => {
    const [scrollY, setScrollY] = useState(0);

    // Update scrollY when the user scrolls
    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollEffect = {
        transform: `translateY(${scrollY * 0.05}px) rotate(-${scrollY * 0.005}deg)`, // Adjust the multiplier for more or less effect
    };

    let types = [
        "10ft Container (smaller standard container)",
        "10ft Refrigerated (Reefer) Container",
        "20ft Dry Container",
        "20ft High Cube Container (taller than standard 20ft containers)",
        "20ft Open Top Container",
        "20ft Flat Rack Container",
        "20ft Double Door Container (for easy access from both ends)",
        "20ft Tank Container (for liquids and gases)",
        "20ft Pallet Wide Container (designed to accommodate wider pallets)",
        "20ft Insulated Container (for temperature-sensitive cargo)",
        "20ft Open Side Container (with doors on the side for easy access)",
        "20ft Modular Container (used for modular building)",
        "20ft Refrigerated Container (High Cube)",
        "40ft Dry Container",
        "40ft High Cube (HC) Dry Container",
        "40ft Open Top Container",
        "40ft Flat Rack Container",
        "40ft Refrigerated (Reefer) Container",
        "40ft Double Door Container",
        "40ft High Cube Container",
        "40ft Tank Container",
        "40ft Pallet Wide Container",
        "40ft Insulated Container",
        "40ft Open Side Container",
        "40ft Modular Container",
        "40ft Refrigerated Container (High Cube)",
        "45ft Container (used for additional capacity)",
    ];

    return (
        <div className='flex flex-col gap-10 p-5 sm:p-10 md:p-16 lg:p-20 bg-slate-50 relative overflow-hidden'>
            {/* Background Images with scroll effect */}
            <img
                src={"https://res.cloudinary.com/dfdbnkqcd/image/upload/v1737764366/kanztainer/f0cpuuzqe11fptn1atsw.png"}
                className="absolute -top-40 -left-40 opacity-25 transition-transform duration-300"
                alt=''
                style={scrollEffect}
            />
            <img
                src={"https://res.cloudinary.com/dfdbnkqcd/image/upload/v1737764366/kanztainer/f0cpuuzqe11fptn1atsw.png"}
                className="absolute -bottom-0 -right-40 opacity-25 transition-transform duration-300"
                alt=''
                style={scrollEffect}
            />

            {/* Title Section */}
            <div className='flex flex-col items-center gap-1 z-[10]'>
                <span className='text-[10px] sm:text-[12px] font-bold uppercase'>Variations</span>
                <span className='text-[20px] sm:text-[28px] md:text-[34px] lg:text-[44px] font-bold w-full md:w-[80%] leading-tight flex items-center justify-center'>
                    Types of Containers We Serve
                </span>
            </div>

            {/* Responsive Grid Layout */}
            <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-5 z-[10]'>
                {types.map((x, index) => (
                    <div
                        key={index}
                        className={`px-3 py-2 hover:shadow-md transition-all duration-200 shadow-lg flex items-center relative justify-center text-[10px] md:text-sm font-medium text-center h-20 text-custom-blue hover:bg-custom-yellow group`}
                    >
                        <div className='hidden md:block absolute bottom-3 overflow-ellipsis -right-0 opacity-0 group-hover:opacity-10 text-[60px] font-bold text-red-900'>
                            {
                                x.includes("10ft") ? "10ft" : x.includes("20ft") ? "20ft" : x.includes("40ft") ? "40ft" : "45ft"
                            }
                        </div>
                        {x}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TypesOfContainers;
