import React, { useState } from 'react';
import { Form, Input, Button, message, Spin, Image } from 'antd';
import { PhoneOutlined, MailOutlined, EnvironmentOutlined, LoadingOutlined } from '@ant-design/icons';
import CryptoJS from "crypto-js";
import { Link } from 'react-router-dom';

const ContactForm = () => {

    const [loader, setloader] = useState(false)
    const [form] = Form.useForm();

    const onFinish = async (values) => {

        setloader(true)
        const response = await fetch("https://backend.kanztainer.com/api/send-email", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "api-key": CryptoJS.AES.encrypt(
                    "@@Power2KanztainerByAbdullah",
                    "@@Power2OnlyAccessors"
                )?.toString()
            },
            body: JSON.stringify(values)
        });

        let result = await response.json()

        if (result.success) {
            message.success(result.message)
            form.resetFields()
            return setloader(false)
        }
        else {
            message.error(result.message)
            return setloader(false)
        }

    }


    return (
        <div className="grid grid-cols-1 md:grid-cols-12 gap-10 px-4 md:px-20 md:py-10 bg-white">
            {/* Left Section */}
            <div className="col-span-1 md:col-span-6 flex flex-col gap-6">
                <h2 className="text-3xl md:text-5xl font-bold leading-tight">
                    Have Questions? <br /> Get in touch!
                </h2>
                <p className="text-gray-600 text-[12px] md:text-base w-full md:w-[60%]">
                    Please give us a call, drop us an email or fill out the contact form and we’ll get back to you.
                </p>
                <div className="flex flex-col gap-5 mt-5">
                    {/* <div className="flex items-center gap-2 text-gray-600 text-sm">
                        <EnvironmentOutlined className="text-sm md:text-lg text-custom-blue" />
                        <span className='text-[10px] md:text-base'>785 15th Street, Office 478 Berlin</span>
                    </div> */}
                    <div className="flex items-center gap-2 text-gray-600 text-sm">
                        <MailOutlined className="text-sm md:text-lg text-custom-blue" />
                        <Link to="mailto:admin@kanztainer.com" className='text-[10px] md:text-base'>admin@kanztainer.com</Link>
                    </div>
                    <div className="flex items-center gap-2 text-gray-600 text-sm">
                        <Image src={"https://res.cloudinary.com/dfdbnkqcd/image/upload/v1737764805/kanztainer/v8v7fibia7mkysvq0fds.png"} preview={false} width={18} />
                        <Link to="tel:+971504521775" className='text-[10px] md:text-base font-bold' >+971 50 4521775</Link>
                    </div><div className="flex items-center gap-2 text-gray-600 text-sm">
                        <Image src={"https://res.cloudinary.com/dfdbnkqcd/image/upload/v1737764751/kanztainer/puy8xbeatas0f9ea4knz.png"} preview={false} width={18} />
                        <Link to="tel:+923222431418" className='text-[10px] md:text-base font-bold' >+92 322 2431418</Link>
                    </div>
                </div>
            </div>

            {/* Right Section - Contact Form */}
            <div className="col-span-1 md:col-span-6 w-full">
                <Spin
                    className={`bg-[#017a7409] h-full w-full`}
                    spinning={loader || false}
                    indicator={<LoadingOutlined className='text-[24px] text-custom-primary-700' spin />}
                >
                    <Form
                        form={form}
                        name="contact"
                        layout="vertical"
                        onFinish={onFinish}
                        className="shadow-md p-6 rounded-md w-full"
                    >
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <Form.Item
                                name="name"
                                label={<span className='text-[10px] md:text-sm font-medium'>Name</span>}
                                rules={[{ required: true, message: 'Please enter your name' }]}
                            >
                                <Input className='p-2 outline-none shadow-none focus:shadow-none border-t-0 rounded-none border-x-0 text-[10px] md:text-sm' />
                            </Form.Item>

                            <Form.Item
                                name="email"
                                label={<span className='text-[10px] md:text-sm font-medium'>Email Address</span>}
                                rules={[
                                    { required: true, message: 'Please enter your email address' },
                                    { type: 'email', message: 'Please enter a valid email' },
                                ]}
                            >
                                <Input className='p-2 outline-none shadow-none focus:shadow-none border-t-0 rounded-none border-x-0 text-[10px] md:text-sm' />
                            </Form.Item>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <Form.Item
                                name="phone"
                                label={<span className='text-[10px] md:text-sm font-medium'>Phone</span>}
                                rules={[{ required: true, message: 'Please enter your phone number' }, { pattern: /^(\+?\d{1,4})?((?!000)\d{10,12})$/, message: 'Please enter valid phone number' }]}
                            >
                                <Input className='p-2 outline-none shadow-none focus:shadow-none border-t-0 rounded-none border-x-0 text-[10px] md:text-sm' />
                            </Form.Item>

                            <Form.Item
                                name="subject"
                                label={<span className='text-[10px] md:text-sm font-medium'>Subject</span>}
                                rules={[{ required: true, message: 'Please enter the subject' }]}
                            >
                                <Input className='p-2 outline-none shadow-none focus:shadow-none border-t-0 rounded-none border-x-0 text-[10px] md:text-sm' />
                            </Form.Item>
                        </div>

                        <Form.Item
                            name="message"
                            label={<span className='text-[10px] md:text-sm font-medium'>How can we help you?</span>}
                            rules={[{ required: true, message: 'Please enter your message' }]}
                        >
                            <Input.TextArea rows={4} className='p-2 outline-none shadow-none focus:shadow-none border-t-0 rounded-none border-x-0 text-[10px] md:text-sm' />
                        </Form.Item>

                        <div className='flex items-center justify-start'>
                            <Form.Item>
                                <button type="submit" className="bg-custom-yellow w-full px-3 py-2 font-medium rounded-sm shadow-sm hover:bg-custom-blue transition-all duration-200 text-white text-[10px] md:text-sm">
                                    Get in Touch
                                </button>
                            </Form.Item>
                        </div>
                    </Form>
                </Spin>
            </div>
        </div>
    );
};

export default ContactForm;
