import React, { useState, useEffect } from 'react';
import Home from './pages/home';
import Header from './pages/components/globals/header';
import Footer from './pages/components/globals/footer';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import AboutUs from './pages/aboutus';
import ContactUs from './pages/contact';
import Services from './pages/services';

const App = () => {

  return (
    <Router>

      <Routes>
        <Route
          path="/"
          element={
            <div className='relative'>
              <Header />
              <Home />
              <Footer />
            </div>
          } />
        <Route
          path="/about-us"
          element={
            <div className='relative'>
              <Header />
              <AboutUs />
              <Footer />
            </div>
          } />
        <Route
          path="/contact-us"
          element={
            <div className='relative'>
              <Header />
              <ContactUs />
              <Footer />
            </div>
          } />
        <Route
          path="/services"
          element={
            <div className='relative'>
              <Header />
              <Services />
              <Footer />
            </div>
          } />
      </Routes>

    </Router>
  );
};

export default App;